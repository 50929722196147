<template>
  <div class="inicial-container">
    <p class="disclaimer">
      Mapeamento de arquivo
      <span class="material-symbols-outlined helpIcon" id="helpIconMapeamento">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Mapeamento do cabeçalho do arquivo e do formato utilizado nas datas.
          <br />
          Selecionar quais colunas do arquivo de entrada corresponde ao
          <strong>Layout obrigatório</strong> disponibilizado pela Outbox.
          <br />
          Colunas nomeadas iguais as do layout serão mapeadas automáticamente.
          <br />
          <strong
            >Caso seja escolhido um separador do cabeçalho errado, as colunas
            disponibilizadas para comparação com o layout da Outbox estarão
            incorretas</strong
          >
        </md-tooltip>
      </span>
    </p>
    <div class="field">
      <div class="box-table">
        <div class="title-info" v-if="!visualizaTabela">
          <span
            class="material-symbols-outlined title-icon"
            @click="visualizaTabela = true"
          >
            read_more
          </span>
          Visualizar arquivo (5 linhas iniciais)
        </div>
        <div class="title-info" v-if="visualizaTabela">
          <span
            class="material-symbols-outlined title-icon off"
            @click="visualizaTabela = false"
          >
            read_more
          </span>
          Esconder arquivo
        </div>
        <preVisualizacaoTabela
          v-if="visualizaTabela"
          :file="file"
          :separadorDefinido="separadorDefinido"
        />
      </div>
      <UploadDadosMap
        @recebeInfoCabecalho="recebeInfoCabecalho"
        @recebeColunas="recebeColunas"
        :file="file"
        :firstLine="firstLine"
        :secondLine="secondLine"
        :opcaoPessoaSelecionada="opcaoPessoaSelecionada"
      />
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import UploadDadosMap from "@/components/Inputs/UploadDadosMap.vue";
import preVisualizacaoTabela from "@/components/Inputs/preVisualizacaoTabela.vue";

export default {
  components: {
    UploadDadosMap,
    preVisualizacaoTabela,
  },

  props: {
    file: File,
    firstLine: String,
    secondLine: String,
    opcaoPessoaSelecionada: String,
  },

  data() {
    return {
      separadorDefinido: "",
      fileColuns: [],
      finalHeader: "",
      finalFormat: "",
      visualizaTabela: false,
    };
  },

  methods: {
    recebeInfoCabecalho(h, f) {
      this.finalHeader = h;
      this.finalFormat = f;
      this.$emit("recebeInfoCabecalho", this.finalHeader, this.finalFormat);
    },

    recebeColunas(c, s) {
      this.fileColuns = c;
      this.separadorDefinido = s;
      this.$emit("recebeColunas", this.fileColuns, this.separadorDefinido);
    },
  },

  computed: {
    ...mapWritableState(useBacktestMemoryStore, [
      "separadorDefinidoMemory",
    ]),
  },

  created() {
    if (!this.opcaoPessoaSelecionada || !this.file) {
      window.location.href = "/backtest/nova/0";
    }

    if (this.separadorDefinidoMemory != "") {
      this.separadorDefinido = this.separadorDefinidoMemory;
    }
  },
};
</script>

<style lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
}

.inicial-container .disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}

@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
}
.field {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.box-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
}
.title-info {
  font-weight: 300;
  font-size: 15px;
  gap: 10px;
  display: flex;
  justify-content: center;
  color: #666666;
}

.title-icon {
  cursor: pointer;
  color: #666666;

  &:hover {
    transform: scale(1.1);
  }
  &.off {
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }
}
</style>
