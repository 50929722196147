<template>
  <div class="view-wrapper" v-if="liberaTela">
    <StepsBacktest
      v-if="verificaRotasSteps('normal')"
      :projectName="projectName"
    />

    <div class="view">
      <router-view
        @recuperaUsuario="recuperaUsuario"
        @selecionaInicial="selecionaInicial"
        @selectFile="selectFile"
        @readFile="readFile"
        @resultadoStatus="resultadoStatus"
        @showErrorStatus="showErrorStatus"
        @recebeInfoCabecalho="recebeInfoCabecalho"
        @recebeColunas="recebeColunas"
        @resetResume="resetResume"
        @projectNameFunc="projectNameFunc"
        :usuario="usuario"
        :produtos="produtos"
        :produtosLotes="produtosLotes"
        :file="file"
        :firstLine="firstLine"
        :secondLine="secondLine"
        :campos="campos"
        :opcaoPessoaSelecionada="opcaoPessoa"
        :resultadoLote="resultadoLote"
        :errorAlert="errorAlert"
        :fileColuns="fileColuns"
        :separadorDefinido="separadorDefinido"
        :finalHeader="finalHeader"
        :finalFormat="finalFormat"
        :projectName="projectName"
      />
    </div>
  </div>
</template>

<script>
import "aos/dist/aos.css";
import StepsBacktest from "@/components/Visuais/StepsBacktest.vue";
import Swal from "sweetalert2";
import { produtosStore } from "@/stores/produtos";
import { mapActions, mapWritableState } from "pinia";
import { lotesStore } from "../../stores/lotes";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";

export default {
  components: {
    StepsBacktest,
  },

  data() {
    return {
      liberaTela: false,
      file: null,
      firstLine: "",
      secondLine: "",
      campos: [],
      opcaoPessoa: "",
      opcaoAbordagem: "",
      resultadoLote: null,
      errorAlert: "",
      separadorDefinido: "",
      fileColuns: [],
      finalHeader: "",
      finalFormat: "",
      projectName: "",
      usuario: null,
    };
  },
  methods: {
    recuperaUsuario(u) {
      this.usuario = u;
    },

    selectFile(f) {
      this.file = f;
    },
    readFile(l, s) {
      this.firstLine = l;
      this.secondLine = s;
    },

    verificaRotasSteps(step) {
      var rota = this.$route.fullPath.slice(-1);
      if (step == "normal") {
        if (parseInt(rota) < 7) {
          return true;
        } else {
          return false;
        }
      }
    },

    selecionaInicial(opcao) {
      this.opcaoPessoa = opcao;
    },

    resultadoStatus(res) {
      this.resultadoLote = res;
    },

    showErrorStatus(res) {
      this.errorAlert = res;
    },
    recebeInfoCabecalho(h, f) {
      this.finalHeader = h;
      this.finalFormat = f;
    },
    recebeColunas(c, s) {
      this.fileColuns = c;
      this.separadorDefinido = s;
    },
    resetResume() {
      this.file = null;
      this.fileColuns = [];
      this.separadorDefinido = "";
      this.finalHeader = "";
      this.finalFormat = "";
    },

    projectNameFunc(p) {
      this.projectName = p;
    },

    ...mapActions(produtosStore, ["getProdutos"]),
    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },
  computed: {
    ...mapWritableState(produtosStore, {
      produtos: "produtos",
      produtosLotes: "produtosLotes",
    }),
    ...mapWritableState(useBacktestMemoryStore, ["abordagemConsulta"]),
  },
  async mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    await this.getProdutos();

    this.liberaTela = true;
    const store = lotesStore();
    store.$reset();
  },

  watch: {
    opcaoPessoa: function (newVal, oldVal) {
      this.produtos.forEach((produto) => {
        produto.Selecionado = false;
      });
      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Disabled = false;
        campo.Requerido = false;
      });
    },
  },

  created() {},

  beforeRouteLeave(to, from, next) {
    if (this.projectName && !this.resultadoLote && !this.errorAlert) {
      Swal.fire({
        icon: "warning",
        html: "<font size='5'>Atenção! Ao sair da página os dados preenchidos serão perdidos. </br> </font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Continuar',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Cancelar',
        cancelButtonAriaLabel: "Cancelar",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.view-wrapper {
  margin-top: 10px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;

  @include mediaQueryMax(small) {
    min-height: 230vh;
  }
}

.view {
  width: 100%;
  display: inline;
  align-items: flex-start;

  @include mediaQueryMax(small) {
    height: 900px;
  }
}

@media (min-width: 1024px) {
  .view-wrapper {
    margin-top: 30px;
    gap: 30px;
  }
}
</style>
