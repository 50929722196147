<template>
  <div class="inicial-container">
    <p class="disclaimer">
      Consultas disponíveis
      <span
        id="helpIconSelecaoAbordagem"
        class="material-symbols-outlined helpIcon"
      >
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Selecione a consulta a ser realizada neste Backtest <br />
          <strong>Box de atributos</strong> são agrupamentos de informações
          encontradas em books ou modelos Outbox. <br />
          <strong>Box de modelos</strong> são os resultados de processamentos de
          modelos com uma finalidade específica.
        </md-tooltip>
      </span>
    </p>
    <div class="opcoesRotas">
      <OpcaoRotas
        :rota="'Box de Atributos'"
        :clicked="clicked"
        @recebeRota="recebeRota"
      />
      <OpcaoRotas
        :rota="'Box de Modelos'"
        :clicked="clicked"
        @recebeRota="recebeRota"
      />
    </div>
    <div class="body">
      <md-card class="cardListaBox" ref="scrollableCard">
        <span class="searchBox">
          <md-field
            class="searchInput md-layout-item md-size-25 md-medium-size-35 md-xsmall-size-100"
          >
            <label for="pesquisaBooks">Pesquisar por Box</label>
            <md-input
              id="pesquisaBooks"
              v-model="pesquisaBox"
              @keyup.enter="filtraBooks"
              :disabled="!liberaInput"
            ></md-input>
          </md-field>
          <button class="button buttonSearch" @click="filtraBooks">
            <span class="material-symbols-outlined">search</span>
          </button>
        </span>
        <div class="listaBox mod" v-if="clicked == 'Box de Modelos'">
          <OpcaoBox
            v-for="(box, index) in valoresModelos_display"
            :key="index"
            :nomeBox="box.Valor"
            :description="box.Descricao"
            :clickedBox="clickedBox"
            :clicked="clicked"
            :tipoPessoaEscolhida="box.TipoPessoaConsulta"
            @recebeBox="recebeBox"
          />
        </div>
        <span class="listaBox atr" v-if="clicked == 'Box de Atributos'">
          <OpcaoBox
            v-for="(box, index) in valoresAtributos_display"
            :key="index"
            :nomeBox="box.Valor"
            :description="box.Descricao"
            :clickedBox="clickedBox"
            :clicked="clicked"
            :tipoPessoaEscolhida="box.TipoPessoaConsulta"
            @recebeBox="recebeBox"
          />
        </span>
        <span
          class="boxCardAviso"
          v-if="
            (valoresAtributos_display.length == 0 &&
              valoresModelos_display.length == 0) ||
            (valoresAtributos_display.length == 0 &&
              clickedBox == '' &&
              !liberaSpinner &&
              valoresAtributos.length != 0 &&
              pesquisaBox != '') ||
            (valoresModelos_display.length == 0 &&
              clickedBox == '' &&
              !liberaSpinner &&
              valoresAtributos.length != 0 &&
              pesquisaBox != '')
          "
        >
          <md-card class="cardAviso">Nenhuma box encontrada</md-card>
        </span>
        <span
          class="boxCardAviso"
          v-if="
            pesquisaBox == '' &&
            valoresAtributos.length == 0 &&
            clicked == 'Box de Atributos' &&
            !liberaSpinner
          "
        >
          <md-card class="cardAviso"
            >Usuário não possui permissão em nenhum Box de Atributos</md-card
          >
        </span>
        <span
          class="boxCardAviso"
          v-if="
            pesquisaBox == '' &&
            valoresModelos.length == 0 &&
            clicked == 'Box de Modelos' &&
            !liberaSpinner
          "
        >
          <md-card class="cardAviso"
            >Usuário não possui permissão em nenhum Box de Modelos</md-card
          >
        </span>
        <div v-if="liberaSpinner">
          <Loading class="spinnerEsq" />
        </div>
      </md-card>
      <md-card class="cardListaAtrExt">
        <md-card class="cardListaAtrInside">
          <span class="searchBox">
            <md-field
              class="searchInput md-layout-item md-size-25 md-medium-size-35 md-xsmall-size-100"
            >
              <label for="pesquisaBooks">Pesquisar por Atributo</label>
              <md-input
                id="pesquisaBooks"
                v-model="pesquisaAtrib"
                @keyup.enter="filtraAtributo"
                :disabled="!liberaInput"
              ></md-input>
            </md-field>
            <button class="button buttonSearch" @click="filtraAtributo">
              <span class="material-symbols-outlined">search</span>
            </button>
          </span>
          <span
            class="msgCarregando"
            v-if="this.listaAtributos.length == 0 && clickedBox != ''"
          >
            Aguarde...
          </span>
          <DetalhesRotas
            v-if="
              this.listaAtributos_display.length != 0 &&
              ((clicked == 'Box de Modelos' && valoresModelos.length != 0) ||
                (clicked == 'Box de Atributos' && valoresAtributos.length != 0))
            "
            :clickedBox="clickedBox"
            :layoutBox="layoutBox"
            :listaAtributos="listaAtributos"
            :listaAtributos_display="listaAtributos_display"
            :liberaSpinnerCard="liberaSpinnerCard"
          />
          <span
            class="boxCardAviso"
            v-if="
              (listaAtributos_display.length == 0 && pesquisaAtrib != '') ||
              (this.listaAtributos.length == 0 &&
                clickedBox == '' &&
                !liberaSpinnerCard) ||
              (pesquisaBox == '' &&
                valoresAtributos.length == 0 &&
                clicked == 'Box de Atributos' &&
                !liberaSpinnerCard) ||
              (pesquisaBox == '' &&
                valoresModelos.length == 0 &&
                clicked == 'Box de Modelos' &&
                !liberaSpinnerCard)
            "
          >
            <md-card class="cardAviso">Nenhum atributo encontrado</md-card>
          </span>
          <div v-if="liberaSpinnerCard && clickedBox != ''">
            <Loading class="spinnerDir" />
          </div>
          <div class="boxButtons">
            <button class="button-backstest" @click="prevStep()">
              ANTERIOR
            </button>
            <button
              class="button-backstest"
              @click="nextStep()"
              :disabled="clickedBox == ''"
            >
              PRÓXIMA
            </button>
          </div>
        </md-card>
      </md-card>
    </div>
  </div>
</template>

<script>
import { userService } from "@/services";
import { lotesService } from "@/services";
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import OpcaoRotas from "@/components/Inputs/OpcaoRotas.vue";
import OpcaoBox from "@/components/Inputs/OpcaoBox.vue";
import DetalhesRotas from "@/components/Inputs/DetalhesRotas.vue";
import Loading from "@/components/Loading";

export default {
  components: {
    OpcaoRotas,
    OpcaoBox,
    DetalhesRotas,
    Loading,
  },

  props: {
    produtos: Array,
    opcaoPessoaSelecionada: String,
    projectName: String,
  },
  data() {
    return {
      valoresAtributos: [],
      valoresAtributos_display: [],
      valoresModelos: [],
      valoresModelos_display: [],
      booksTemp: [],
      tipoPessoaEscolhida: "",
      clicked: "Box de Atributos",
      clickedBox: "",
      rota: "",
      nomeBox: "",
      description: "",
      layoutBox: {},
      listaAtributos: [],
      listaAtributos_display: [],
      liberaSpinner: false,
      liberaSpinnerCard: false,
      pesquisaBox: "",
      pesquisaAtrib: "",
      liberaInput: false,
    };
  },

  methods: {
    recebeRota(r) {
      this.clicked = r;
      this.clickedBox = "";
      this.pesquisaBox = "";
      this.pesquisaAtrib = "";
      this.filtraBooks();

      if (
        (this.valoresModelos_display.length != 0 &&
          this.clicked == "Box de Modelos") ||
        (this.valoresAtributos_display != 0 &&
          this.clicked == "Box de Atributos")
      ) {
        this.liberaInput = true;
        this.filterByRota();
        this.filtraAtributo();
        this.recebeBox(this.clickedBox);
      } else {
        this.liberaInput = false;
      }
    },

    filterByRota() {
      if (this.abordagemConsulta != "" && this.clicked == this.rotaConsulta) {
        this.clickedBox = this.abordagemConsulta;
      }
      if (
        this.clicked == "Box de Modelos" ||
        (this.clicked == "Box de Modelos" && this.clicked != this.rotaConsulta)
      ) {
        this.booksTemp = this.valoresModelos_display;
        if (this.abordagemConsulta == "") {
          this.clickedBox = this.valoresModelos_display[0].Valor;
        }
      }
      if (
        this.clicked == "Box de Atributos" ||
        (this.clicked == "Box de Atributos" &&
          this.clicked != this.rotaConsulta)
      ) {
        if (this.abordagemConsulta == "") {
          this.clickedBox = this.valoresAtributos_display[0].Valor;
        }
        this.booksTemp = this.valoresAtributos_display;
      }
    },

    verificaTipoPessoa() {
      if (
        this.clicked == "Box de Modelos" &&
        !this.valoresModelos_display.some(
          (item) => item.Valor === this.clickedBox
        )
      ) {
        this.clickedBox = this.valoresModelos_display[0].Valor;
      }
      if (
        this.clicked == "Box de Atributos" &&
        !this.valoresAtributos_display.some(
          (item) => item.Valor === this.clickedBox
        )
      ) {
        this.clickedBox = this.valoresAtributos_display[0].Valor;
      }
    },

    recebeBox(b) {
      this.clickedBox = b;
      if (this.clicked == "Box de Modelos") {
        this.liberaSpinnerCard = true;

        this.recuperaLayoutMod(this.clickedBox);
      }
      if (this.clicked == "Box de Atributos") {
        this.liberaSpinnerCard = true;

        this.recuperaLayoutAtr(this.clickedBox);
      }
    },

    async recuperaRotas() {
      this.liberaSpinner = true;
      this.liberaSpinnerCard = true;
      await userService.recuperaRotasUsuario().then(
        (usuario) => {
          usuario.forEach((rota) => {
            if (rota.CodigoRota == "v3/box") {
              rota.PermissoesParametro.forEach((permissao) => {
                this.valoresAtributos = permissao.DominioValores;
              });
            }
            if (rota.CodigoRota == "v3/modelo") {
              rota.PermissoesParametro.forEach((permissao) => {
                this.valoresModelos = permissao.DominioValores;
              });
            }
            this.liberaInput = true;
          });
        },
        (error) => {
          this.msgErroUsuario = "Erro ao carregar dados do usuário logado.";
        }
      );
      this.filterByTipoPessoa();
    },

    filterByTipoPessoa() {
      if (this.opcaoPessoaSelecionada == "Pessoa Física") {
        this.valoresAtributos = this.valoresAtributos.filter(
          (item) => item.TipoPessoaConsulta != "Jurídica"
        );
        this.valoresModelos = this.valoresModelos.filter(
          (item) => item.TipoPessoaConsulta != "Jurídica"
        );
      }
      if (this.opcaoPessoaSelecionada == "Pessoa Jurídica") {
        this.valoresAtributos = this.valoresAtributos.filter(
          (item) => item.TipoPessoaConsulta != "Física"
        );
        this.valoresModelos = this.valoresModelos.filter(
          (item) => item.TipoPessoaConsulta != "Física"
        );
      }
      this.valoresModelos_display = this.valoresModelos;
      this.valoresAtributos_display = this.sortedValoresAtributos;
      this.liberaSpinner = false;

      this.filterByRota();
      this.verificaTipoPessoa();
      this.recebeBox(this.clickedBox);
    },

    async recuperaLayoutAtr(box) {
      this.layoutBox = {};
      const choosedBox = box;

      const objetoHistorico = {
        cod_ref: choosedBox,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutBox = await lotesService.recuperaLayoutAtributo(
        query.toString()
      );
      this.recuperaAtributos();
    },

    async recuperaLayoutMod(box) {
      this.layoutBox = {};

      const choosedBox = box;

      const objetoHistorico = {
        cod_ref: choosedBox,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutBox = await lotesService.recuperaLayoutModelo(
        query.toString()
      );
      this.recuperaAtributos();
    },

    recuperaAtributos() {
      this.listaAtributos = [];
      this.listaAtributos_display = [];
      this.listaAtributos = this.layoutBox.ListaAtributos;
      this.listaAtributos_display = this.layoutBox.ListaAtributos;
      this.liberaSpinnerCard = false;
    },

    filtraBooks() {
      if (this.pesquisaBox.trim().length > 0) {
        this.valoresAtributos_display = this.booksFiltrados;
        this.valoresModelos_display = this.booksFiltrados;
      } else {
        if (this.clicked == "Box de Modelos") {
          this.valoresModelos_display = this.valoresModelos;
        }
        if (this.clicked == "Box de Atributos") {
          this.valoresAtributos_display = this.sortedValoresAtributos;
        }
      }
    },

    filtraAtributo() {
      if (this.pesquisaAtrib.trim().length > 0) {
        this.listaAtributos_display = this.atributosFiltrados;
      } else {
        this.listaAtributos_display = this.listaAtributos;
      }
    },

    nextStep() {
      this.setAbordagem(this.clicked, this.clickedBox, this.layoutBox);
      this.$router.push("/backtest/nova/3");
    },
    prevStep() {
      this.setAbordagem(this.clicked, this.clickedBox, this.layoutBox);
      this.$router.push("/backtest/nova/1");
    },

    ...mapActions(useBacktestMemoryStore, ["setAbordagem"]),
  },

  computed: {
    ...mapWritableState(useBacktestMemoryStore, ["abordagemConsulta", "rotaConsulta"]),

    sortedValoresAtributos() {
      const filteredItems = this.valoresAtributos.filter((item) =>
        item.Valor.includes("CHP")
      );
      const otherItems = this.valoresAtributos.filter(
        (item) => !item.Valor.includes("CHP")
      );
      const sortedItems = filteredItems.concat(otherItems);
      return sortedItems;
    },

    booksFiltrados() {
      if (this.pesquisaBox.trim().length > 0) {
        const searchTerm = this.pesquisaBox.toLowerCase();

        return this.booksTemp.filter(
          (item) =>
            (item.Valor && item.Valor.toLowerCase().includes(searchTerm)) ||
            (item.Descricao &&
              item.Descricao.toLowerCase().includes(searchTerm))
        );
      }
      return this.booksTemp;
    },

    atributosFiltrados() {
      if (this.pesquisaAtrib.trim().length > 0) {
        const searchTerm = this.pesquisaAtrib.toLowerCase();
        return this.listaAtributos.filter(
          (item) =>
            item.Identificador.toLowerCase().includes(searchTerm) ||
            item.Descricao.toLowerCase().includes(searchTerm)
        );
      }
      return this.listaAtributos;
    },
  },
  mounted() {},

  async created() {
    this.valoresModelos_display = [];
    this.valoresAtributos_display = [];

    if (this.rotaConsulta != "") {
      this.clicked = this.rotaConsulta;
    }

    await this.recuperaRotas();
    if (!this.opcaoPessoaSelecionada) {
      window.location.href = "/backtest/nova/1";
    }
    if (!this.projectName) {
      window.location.href = "/backtest/nova/0";
    }
    if (this.abordagemConsulta != "") {
      this.recebeRota(this.rotaConsulta);
    }
  },
};
</script>

<style lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inicial-container .disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}

.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
}

@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include mediaQueryMax(medium) {
    flex-direction: column;
  }
}
.cardListaBox {
  width: 40%;
  height: 550px;
  box-shadow: gray 0px 1px 10px;
  margin: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;

  @include mediaQueryMax(medium) {
    width: 90%;
  }

  @include mediaQueryMax(small) {
    margin-bottom: 30px;
  }
}
.cardListaAtrExt {
  background-color: #f9f7ff;
  border-radius: 5px;
  height: 550px;
  width: 60%;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 0px 10px 0 0 !important;
  padding: 10px;
  box-shadow: gray 0px 1px 10px;

  @include mediaQueryMax(medium) {
    width: 90%;
  }
  @include mediaQueryMax(small) {
    height: 600px;
  }
}

.cardListaAtrInside {
  margin: 0px !important;
  padding: 10px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.listaBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.opcoesRotas {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.spinnerEsq {
  // width: 20px;
  position: absolute;
  z-index: 1000;
  left: 25%;
  top: 30%;
}
.spinnerDir {
  // width: 20px;
  position: absolute;
  z-index: 1000;
  left: 35%;
  top: 30%;
}

.searchBox {
  display: flex;
  padding: 10px;
  width: 100%;
  background-color: rgba(194, 194, 194, 0.15);
  border-radius: 10px;
}
.searchInput {
  margin: 10px 10px 0 0;
  padding: 5px 0;
  border-radius: 20%;
  min-width: 250px;

  @include mediaQueryMax(small) {
    min-width: 100px;
  }
}

.buttonSearch {
  width: 40px;
  margin: 0 0 10px 0;
  background-color: transparent;
  border: none;
}

.buttonSearch:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.buttonSearch:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}
.msgCarregando {
  font-size: 1.5rem;
  color: gray;
  opacity: 0.7;
  height: 100%;
}
.boxCardAviso {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.cardAviso {
  width: 90%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.boxButtons {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}
</style>
