<template>
  <div class="inicial-container">
    <div class="content container-fluid text-center">
      <div
        class="cardTitle"
        v-bind:style="{ 'background-image': 'url(' + fundoOutbox + ')' }"
      >
        <h3 class="disclaimer">
          Bem-vindo ao processo de execução de BACKTESTS
        </h3>
      </div>
      <md-card class="boxBacktestExt">
        <md-card class="boxBacktest">
          <md-card-content class="label">
            <span>
              <p class="introUp">
                Este processo irá orientá-lo em todos os passos necessários para
                realizar um <strong>Backtest</strong>. Para
                <strong>carregar</strong> o Lote e
                <strong>executar o Backtest</strong>, o usuário deverá seguir os
                seguintes passos:
              </p>
            </span>
            <!-- <span class="boxIcon">
              <span class="material-symbols-outlined iconBacktest"> post_add </span>
            </span> -->
          </md-card-content>
          <md-card-content class="field">
            <div class="steps__container">
              <div class="item">
                <p class="textItem" id="passo1">
                  Passo 1 <br />
                  <span class="material-symbols-outlined iconItem">face</span>
                </p>
              </div>
              <b-tooltip
                target="passo1"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"> Tipo de Backtest. </strong>
                <br />
                Selecionar se a execução de Lote realizada será para pessoas
                físicas ou jurídicas.
              </b-tooltip>

              <div class="item">
                <p class="textItem" id="passo2">
                  Passo 2
                  <span class="material-symbols-outlined iconItem"
                    >checklist</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo2"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"> Consultas disponíveis. </strong>
                <br />
                Selecionar o tipo de consulta a ser realizada neste Backtest
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo3">
                  Passo 3
                  <span class="material-symbols-outlined iconItem"
                    >cloud_upload</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo3"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"> Upload de Arquivo. </strong>
                <br />
                Selecionar o arquivo que será usado para realizar o Backtest.
                <strong class="tooltipResults">
                  O arquivo de entrada precisa seguir as regras descritas no
                  passo.</strong
                >
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo4">
                  Passo 4
                  <span class="material-symbols-outlined iconItem">map</span>
                </p>
              </div>
              <b-tooltip
                target="passo4"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"> Mapeamento de arquivo. </strong>
                <br />
                Realizar o mapeamento do cabeçalho do arquivo e do formato
                utilizado nas datas.
                <strong class="tooltipResults">
                  As colunas mapeadas serão as dispostas no Layout
                  Completo.</strong
                >
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo5">
                  Passo 5
                  <span class="material-symbols-outlined iconItem"
                    >stacked_bar_chart</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo5"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults">
                  Resumo do Lote a submeter.
                </strong>
                <br />
                Revisão das informações do arquivo enviado e dos mapeamentos
                realizados no passo anterior.
                <strong class="tooltipResults"
                  >Alterações nos passos anteriores podem ocorrer caso o usuário
                  ache necessário.</strong
                >
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo6">
                  Passo 6
                  <span class="material-symbols-outlined iconItem"
                    >insights</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo6"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults">
                  Status de carregamento do Lote.
                </strong>
              </b-tooltip>
            </div>
            <div class="label">
              <p class="intro">
                O processo de <strong>Backtest</strong> irá gerar um
                <strong>Lote de Consultas</strong> que posteriormente podem ter
                o seu resultado integrado com outras plataformas
                <strong>Stepwise/Outbox</strong>. <br />
              </p>
            </div>
            <h5 class="campoTitle">Adicione o Nome do Projeto:</h5>
            <div class="campoProjeto">
              <div class="campo">
                <md-field
                  class="inputField"
                  :class="messageClass"
                  :md-counter="false"
                >
                  <md-icon>input</md-icon>
                  <label class="nameProject"
                    >Nome do Projeto (3 a 120 caracteres)</label
                  >
                  <md-input
                    type="text"
                    v-model="projectName"
                    maxlength="120"
                    required
                    @input="validaNome"
                    @keyup.enter="startProject"
                  />
                  <span class="md-error">Limite de caracteres alcançado</span>
                </md-field>
              </div>
            </div>
          </md-card-content>
          <md-card-content class="footer">
            <div id="buttonStart" class="buttonInit">
              <button
                class="button-backstest"
                @click="startProject"
                :disabled="projectName.length < 3 || projectName.length > 120"
              >
                INICIAR
              </button>
            </div>
            <b-tooltip
              v-if="!projectName"
              target="buttonStart"
              triggers="hover"
              placement="bottom"
              variant="secondary"
              class="tooltipResults"
              boundary="window"
            >
              Adicione o <strong> Nome do Projeto </strong> para continuar.
            </b-tooltip>
          </md-card-content>
        </md-card>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";

export default {
  name: "telaInicialBacktest",

  props: {},
  components: {},

  data() {
    return {
      projectName: "",
      skynetLogo: require("/public/img/Skynet.png"),
      fundoOutbox: require("/public/img/bannerOutbox.png"),
      hasMessages: false,
    };
  },

  methods: {
    startProject() {
      if (this.projectName.length > 2) {
        this.resetAll();
        this.$router.push("/backtest/nova/1");
        this.$emit("projectNameFunc", this.projectName);
      }
    },
    validaNome() {
      if (this.projectName.length > 119) {
        this.hasMessages = true;
      } else {
        this.hasMessages = false;
      }
    },
    ...mapActions(useBacktestMemoryStore, ["resetAll"]),
  },

  created() {
    this.projectName = "";
  },

  computed: {
    messageClass() {
      return {
        "md-invalid": this.hasMessages,
      };
    },
  },
};
</script>

<style  lang="scss" scoped>
.inicial-container {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: default;
}
.disclaimer {
  font-weight: 700;
  font-size: 2rem !important;
  text-align: center;
  margin: 10px !important;
  word-wrap: break-all;
  color: #fff;
  text-shadow: -1px -1px 0px rgb(0, 0, 0), -1px 1px 0px rgb(0, 0, 0),
    1px -1px 0px rgb(0, 0, 0), 1px 0px 0px rgb(0, 0, 0);
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTitle {
  display: flex;
  width: 80%;
  min-height: 90px;
  padding: 10px;
  row-gap: 10px;
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 30%);
  border-radius: 10px;
  @include flex-center(row);
}
.boxBacktestExt {
  width: 80% !important;
  padding: 5px 15px !important;
  display: flex;
  align-items: center;
  background-color: #f9f7ff;
}
.boxBacktest {
  background-color: #fff;
  padding: 0 0 0 0 !important;
  min-height: 400px;
  max-height: 400px;
  width: 100% !important;
  max-height: 100%;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;

  @include mediaQueryMax(small) {
    min-height: 600px;
  }
}
.label {
  margin: 10px 0px !important;
  padding: 0px 10px;
  top: -18%;
  min-height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.field {
  min-height: 250px;
  width: 100%;
  display: flex;
  top: -20%;
  margin: 0%;
  padding: 0px 0;
  flex-direction: column;

  @include mediaQueryMax(small) {
  }
}

.footer {
  min-height: 30px;
  height: 50px;
  width: 100%;
  display: flex;
  background-color: transparent;
  padding: 10px;
  justify-content: flex-end;

  @include mediaQueryMax(small) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.introUp {
  font-size: 1rem;
}

.intro {
  width: 70%;
  font-size: 1rem;
  margin: 0px 0;
}

.steps__container {
  width: 100%;
  min-height: 120px;
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 0 0 20px 0;
  @include mediaQueryMax(large) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1.5rem;
  }
  @include mediaQueryMax(medium) {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
  @include mediaQueryMax(small) {
    min-height: 180px;
    margin: 0;
  }
}

.item {
  width: 110px;
  height: 110px;
  margin: 0px 25px;
  padding: 10px;

  @include mediaQueryMax(small) {
    margin: 0px 10px;
  }
}
.textItem {
  color: #f5f5f5;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1.6px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s ease all;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  background-color: rgba(31, 134, 219, 0.9);
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 30%);
}
.textItem:hover {
  background-color: rgba(0, 31, 117, 0.7);
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 50%);
}

.tooltipResults {
  color: #f5f5f5;
}

.iconItem {
  color: #f5f5f5;
  margin: 3px 0 0 0;
}

@media (min-width: 1024px) {
  .item a {
    width: 60px;
    height: 60px;
  }
}
.campoProjeto {
  width: 95%;
  height: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;

  @include mediaQueryMax(small) {
    height: 45px;
  }
}

.campoTitle {
  max-width: 50%;
  line-height: 2rem;
  font-weight: 600;
  display: flex;
  justify-items: left;
  margin: 20px 0 0 20px;
  @include mediaQueryMax(small) {
    margin: 10px 0 0px 20px;
    max-width: 90%;
  }
}
.campo {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: left;
  align-items: center;
  cursor: pointer;
  @include mediaQueryMax(small) {
    min-height: 4.5rem;
  }
}

.inputField {
  display: flex;
  width: 50%;
  max-width: 60%;
  margin: 0 0 10px 10px;
  cursor: pointer;

  @include mediaQueryMax(small) {
    width: 90%;
    max-width: 90%;
    min-height: 3.7rem;
    align-items: center;
    justify-items: flex-start;
  }
  @include mediaQueryMax(medium) {
    width: 90%;
    max-width: 90%;
  }
}
.nameProject {
  @include mediaQueryMax(small) {
    left: 0.7rem;
  }
}
.buttonInit {
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include mediaQueryMax(small) {
    min-height: 0;
    width: 100%;
    margin: 0px 0;
    justify-content: flex-end;
  }
}
</style>